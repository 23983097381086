<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Tindakan</h5>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Nama:</label>
                    <div class="col-lg-6">
                        <input
                            type="text"
                            class="form-control"
                            v-model="tindakan.nama"
                            v-validate="'required'"
                            data-vv-as="Nama"
                            data-vv-scope="form-1"
                            name="nama"
                            :class="{'is-invalid': errors.has('form-1.nama') }"/>
                        <div v-show="errors.has('form-1.nama')" class="invalid-feedback">{{ errors.first('form-1.nama') }}</div>
                    </div>
                    <div class="col-lg-2">
                        <button class="btn btn-default btn-bold btn-upper btn-font-md" type="button" @click="addTindakan()">Add</button>
                    </div>
                </div>
               <div class="kt-portlet__body p-0" v-if="isLoaded">
                    <data-tables :table="table"></data-tables>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100 justify-content-center">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-clean btn-bold btn-upper btn-font-md" @click="clearData()" >Close</button>
                <button type="button" class="btn btn-default btn-bold btn-upper btn-font-md" @click="submitData()">Save</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import Tindakan from "../../../model/tindakan-model";
import ParentTindakan from "../../../model/parent-tindakan-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const TindakanRepository = RepositoryFactory.get("tindakan");
import DataTables from "../../_table/DataTables";
import TindakanCheckbox from "./TindakanCheckbox";
export default {
  components: {
    DataTables,
    TindakanCheckbox
  },
  data() {
    var vx = this;
    return {
        tindakan : new Tindakan(),
        parentTindakan : new ParentTindakan(),
        selectedParentTindakan: [],
        selectedTindakan : [],
        listTindakan: [],
        rowIndexPermission: null,
        showLoading: true,
        buttonMode: true,
        isLoaded: false,
        table: {
            tableName: "table_tindakan_skema",
            tableDataResult: [],
            tableColumnDefs: [
                {
                    defaultContent: "-",
                    targets: "_all"
                }
            ],
            order: [[ 0, "asc" ]],
            tableColumns: [
                {
                    title: "ID",
                    data: "id"
                },
                {
                    title: "Nama",
                    data: "nama"
                },
                {
                    title: "Actions",
                    data: "id",
                    createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                        let tindakanCheckbox = Vue.extend(TindakanCheckbox);
                        let instance = new tindakanCheckbox({
                            propsData: {
                                tindakanVue: vx,
                                rowData: rowData
                            }
                    });
                    instance.$mount();
                    $(cell)
                        .empty()
                        .append(instance.$el);
                    }
                }
            ],
            tableFilter: [
                {
                    title: "Nama",
                    type: "text"
                },
                {
                    title: "Actions",
                    type: "actions"
                }
            ]
        }
    };
  },
  props: {
    modalName: { type: String },
    modalData: { type: Object }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  watch: {

  },
  methods: {
    addTindakan: function () {
        var vx = this;

        this.$validator.validateAll('form-1').then(result => {
            if (result) {
                let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Tindakan akan ditambahkan.",
                swalType: "info",
                onConfirmButton: function () {
                    blockUI.blockModal();
                    vx.tindakan.latest = true;
                    vx.create(vx.tindakan);
                },
                onCancelButton: function () {
                    blockUI.unblockModal();
                }
                });
            return;
            }
        });
    },
    clearData: function () {
        $("#" + this.modalName).modal("hide");
        this.$emit("complete", true);
        this.$emit("update:showModal", false);
    },
    reloadTable: function(mode) {
        var datatable = $("#" + this.table.tableName).DataTable();
        datatable.clear();
        datatable.rows.add(this.listTindakan);
        datatable.draw(mode);
    },
    onInit: function () {
        this.selectedTindakan = this.modalData.parentTindakans.map(x => x.id);
        this.getData();
    },
    submitData: function () {
        this.selectedParentTindakan.forEach(parentTindakan => {
            parentTindakan.subTindakans = [];
            this.modalData.parentTindakans.push(parentTindakan)
        });
        this.clearData();
    },
    async create(payload) {
      var vx = this;
      await TindakanRepository.createTindakan(payload)
        .then(response => {
          new Promise((resolve, reject) => {
            vx.getData(resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Data Tindakan berhasil ditambahkan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockModal();
                vx.tindakan = new Tindakan();
                vx.getData();
              }
            });
          });
        })
        .catch(error => {
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: "Data Tindakan gagal ditambahkan.",
            swalType: "error",
            onConfirmButton: function () {
              blockUI.unblockModal();
            }
          });
        });
    },
    async getData(resolve) {
        new Promise((resolve, reject) => {
            TindakanRepository.get({sort: ['createdDate,DESC'],tindakanParentNull: 'false'}, resolve, reject );
        }).then(response => {
            this.listTindakan = response.data;
            if (this.selectedTindakan.length > 0) {
                this.listTindakan = this.listTindakan.filter(x => this.selectedTindakan.indexOf(x.id) < 0);
            }
            if (this.isLoaded) {
                var datatable = $("#" + this.table.tableName).DataTable();
                datatable.clear();
                datatable.rows.add(this.listTindakan);
                datatable.draw("full-hold");
                if(resolve !== undefined) {
                    resolve(this.listTindakan);
                }
            } else {
                this.table.tableDataResult = this.listTindakan;
                this.isLoaded = true;
            }
        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            this.showToastError('Tindakan Modal : ' + msg);
        })

    },
    showToastError: function(message) {
            toastr.error(message);
        }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.onInit();
  }
}
</script>
